@tailwind base;
@tailwind components;
@tailwind utilities;

#bin-bar::-webkit-scrollbar {
    display: none;
  }

  .zero-index {
    z-index: 0 !important;
  }