form {
    animation-duration: 0.5s;
  }
  
  form.shake {
    animation-name: shake;
  }
  
  @keyframes shake {
    0% {
      transform: translateX 0;
    }
    25% {
    transform: translateX(-10px);
    }
    50% {
    transform: translateX(10px);
    }
    75% {
    transform: translateX(-10px);
    }
    100% {
    transform: translateX(0);
    }
    }
    
    form.error {
    border-color: red;
    }
    
    .error-message {
    color: red;
    margin-top: 10px;
    }