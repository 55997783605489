.admin-tasks-container {
    /* margin: 1rem; */
  }
  
  .loader {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }
  
  .tasks-list {
    display: flex;
    flex-direction: column;
  }
  
  .task-item {
    margin-bottom: 1rem;
  }
  
  .even-row {
    background-color: #f3f3f3;
  }
  
  .odd-row {
    background-color: #ffffff;
  }
  
  .task-card {
    padding: 1rem;
    border-radius: 8px;
  }
  
  .task-card.completed {
    border: 3px solid #4caf50;
  }
  
  .task-card.not-completed {
    border: 3px solid #f44336;
  }
  
  .task-title {
    margin-bottom: 1rem;
    color: #2e4a73;
    font-weight: 500;
  }
  